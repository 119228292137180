<div class="mainContainer vw-100 d-flex flex-column align-items-center justify-content-center" [style.height.px]="screenHeight">
    <div class="cardHolder d-flex align-items-center justify-content-center flex-column gap-4">
        <div class="w-100 d-flex justify-content-center topBar">
            Welcome,
        </div>
        <div class="d-flex flex-column gap-2">
            <label for="username">Mobile Number</label>
            <input 
                pInputText 
                id="username" 
                aria-describedby="username-help" 
                [(ngModel)]="userMobile" />
        </div>
        <div class="d-flex flex-column gap-2">
            <label for="username">Enter Otp</label>
            <p-inputOtp [(ngModel)]="otpValue" [disabled]="!isOtpSent" [length]="6"/>
        </div>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div>
                <p-button [label]="isOtpSent ? 'Verify Otp' : 'Send Otp'" class="w-100" (click)="isOtpSent ? verifyCode() : sendVerificationCode()"/>
            </div>
        </div>
        <hr class="w-100">
        <div class="w-100 d-flex justify-content-center">
            <span class="socialLoginBtn">
                <button type="button" (click)="loginWithGoogle()" class="btn btn-light btn-block"><img src="../../assets/images/googleIcon.png" alt=""> Sign in with Google</button>
            </span>
        </div>
    </div>
</div>
<div id="recaptcha-container"></div>
