<div class="mainContainer">
    <h4>Outlet Timings</h4>
    <hr>
    <div class="mt-4 d-flex flex-column gap-3">
        <div class="dayCard p-2 d-flex flex-column gap-2" *ngFor="let day of days; let index = index">
            <span class="dayTitle">
                {{day}}
            </span>
            <div class="d-flex gap-4">
                <span>
                    <p-calendar [(ngModel)]="startTime" [iconDisplay]="'input'" [showIcon]="true" [timeOnly]="true"
                        inputId="templatedisplay">
                        <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                            <i class="pi pi-clock pointer-events-none" (click)="clickCallBack($event)"></i>
                        </ng-template>
                    </p-calendar>
                </span>
                <span>
                    <p-calendar [(ngModel)]="endTime" [iconDisplay]="'input'" [showIcon]="true" [timeOnly]="true"
                        inputId="templatedisplay">
                        <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                            <i class="pi pi-clock pointer-events-none" (click)="clickCallBack($event)"></i>
                        </ng-template>
                    </p-calendar>
                </span>
                <span>
                    <p-button class="addBtn" severity="primary" label="Save" size="small"
                    (click)="saveTimings(index)"></p-button>
                </span>
            </div>
        </div>
    </div>
</div>