import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularFireModule } from '@angular/fire/compat';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../../environments/environment';
import { LandingPageComponent } from './components/onBoarding/landing-page/landing-page.component';
import { InputOtpModule } from 'primeng/inputotp';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RegistrationComponent } from './components/onBoarding/registration/registration.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { HttpClientModule } from '@angular/common/http';
import { RazorpayService } from './razorpay.service';
import { DialogModule } from 'primeng/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './components/partner/dashboard/dashboard.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OrdersComponent } from './components/partner/orders/orders.component';
import { SidebarModule } from 'primeng/sidebar';
import { SettingsComponent } from './components/partner/settings/settings.component';
import { CalendarModule } from 'primeng/calendar';
import { UserLandingPageComponent } from './components/user/user-landing-page/user-landing-page.component';
import { MenuComponent } from './components/partner/menu/menu.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuCardComponent } from './components/user/menu-card/menu-card.component';
import { AccordionModule } from 'primeng/accordion';
import { TabMenuModule } from 'primeng/tabmenu';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { SliderModule } from 'primeng/slider';
import { GoogleMapComponent } from './components/shared/google-map/google-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    RegistrationComponent,
    DashboardComponent,
    OrdersComponent,
    SettingsComponent,
    UserLandingPageComponent,
    MenuComponent,
    MenuCardComponent,
    GoogleMapComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    InputOtpModule,
    InputTextModule,
    GoogleMapsModule,
    ButtonModule, FileUploadModule, ToastModule, DialogModule, OverlayPanelModule, SidebarModule,
    CalendarModule, DropdownModule, InputTextareaModule, AccordionModule, TabMenuModule, InputIconModule,
    IconFieldModule, SliderModule
  ],
  providers: [RazorpayService],
  bootstrap: [AppComponent]
})
export class AppModule { }
