import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { environment } from '../../environments/environment';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import Razorpay from 'razorpay';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  reCaptchaVerifier: any
  windowRef: any
  userData: any;
  restaurantOwnersCollection: AngularFirestoreCollection;
  restaurantCollection: AngularFirestoreCollection;
  ordersCollection: AngularFirestoreCollection;
  openOrdersCollection: AngularFirestoreCollection;
  usersCollection: AngularFirestoreCollection;
  activeUser: any;
  selectedRestaurantId: string | null | any = null; // Add this line

  constructor(private firebaseAuth: AngularFireAuth, private fireStore: AngularFirestore) {
    this.windowRef = {}; // Initialize windowRef
    this.restaurantOwnersCollection = fireStore.collection('restaurantOwners');
    this.restaurantCollection = fireStore.collection('registeredRestaurants');
    this.ordersCollection = fireStore.collection('orders');
    this.openOrdersCollection = fireStore.collection('openOrders');
    this.usersCollection = fireStore.collection('users');
  }

  signInwithMobile(mobileNumber: any) {   
    const app = firebase.initializeApp(environment.firebaseConfig);
    this.firebaseAuth.signInWithPhoneNumber(mobileNumber,    
    this.windowRef['phoneRecaptchaVerifier'] = new firebase.auth.RecaptchaVerifier('phone-sign-in-recaptcha', {
      'size': 'invisible',
      'callback': function(response: any) {
        console.warn(response);
        
        // reCAPTCHA solved - will proceed with submit function
      },
      'expired-callback': function() {
        // Reset reCAPTCHA?
      }
    }, app)).then((confirmationResult: any) => {
      this.windowRef.confirmationResult = confirmationResult;
      var data = document.getElementById('phone-sign-in-recaptcha') as HTMLElement;
      data.innerHTML = '';
    });
    // this.windowRef['phoneRecaptchaVerifier'].clear();
  }

  verifyOtp(otp: any) {
    return this.windowRef.confirmationResult.confirm(otp)
  }

  async googleSignIn(): Promise<any> {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    try {
      const credential = await signInWithPopup(auth, provider);
      const user = credential.user;
      this.userData = user;
      // Check if user is already registered (verified)
      if (user && user.emailVerified) {
        return user;  // Existing user, return user data
      } else {
        console.log('New user');  // New user, handle appropriately
      }
    } catch (error) {
    //   console.error('Error signing in:', error);
    }
  }

  onBoardUser(uid: string, obj: any) {
    return this.restaurantOwnersCollection.doc(uid).set(obj);
  }

  isNewUser(uid: string) {
    return this.restaurantOwnersCollection.doc(uid).get();
  }

  getRestaurantOwnerDetails(uid: string) {
    return this.restaurantOwnersCollection.doc(uid).get();
  }

  async updateOnBoardingProcess(activeStep: number, uid: string, obj: any) {
    const docRef: AngularFirestoreDocument<any> = this.restaurantOwnersCollection.doc(uid);
    const docSnapshot = await docRef.get().toPromise();
    const currentOnboardingStatus = docSnapshot?.data()?.onBoardingStatus || {};

    // Update Logic: Increment currentStep
    const updateData: Partial<any> = {
      onBoardingStatus: {
        ...currentOnboardingStatus,
        currentStep: activeStep == 5 ? currentOnboardingStatus.currentStep : currentOnboardingStatus.currentStep + 1, // Increment currentStep
        // userDetails: obj, // Update userDetails only on the first step (if needed)
      }
    };

    if (activeStep === 1) {
      updateData['onBoardingStatus'].userDetails = obj; // Update userDetails
      updateData['mobile'] = obj.mobile; // Assuming you also want to set mobile on the first step
    } else if (activeStep === 2) {
      updateData['onBoardingStatus'].userVerification = obj; // Update userVerification
    } else if (activeStep === 3) {
      updateData['onBoardingStatus'].restaurantDetails = obj; 
    } else if (activeStep === 4) {
      updateData['onBoardingStatus'].restaurantVerification = obj; // Update userVerification
    } else if (activeStep === 5) {
      updateData['isPaymentDone'] = true; // Update userVerification
    }

    try {
      await docRef.update(updateData);
      console.log('Document successfully updated!');
    } catch (error) {
      console.error('Error updating document:', error);
    }
  }

  getActiveRestaurantDetails(resId: any) {
    return this.restaurantCollection.doc(resId).get();
  }

  updateOutletTimings(resId: any, obj: any) {
    return this.restaurantCollection.doc(resId).update({restaurantTimings: obj});
  }

  getRestaurantsList() {
    return this.restaurantCollection.get()
  }

  addNewCategory(resId: any, obj: any) {
    return this.restaurantCollection.doc(resId).collection('menu').doc(obj.categoryName).set({"metaData": obj})
  }

  addNewItem(resId: any, categoryId: any, obj: any) {
    return this.restaurantCollection.doc(resId).collection('menu').doc(categoryId).collection('Items').doc(obj.itemName).set(obj);
  }

  getItems(resId: any, categoryId: any) {
    return this.restaurantCollection.doc(resId).collection('menu').doc(categoryId).collection('Items').get();
  }
  
  saveNewOrder(order: any) {
    return this.restaurantCollection.doc(order.resId).collection('activeOrders').doc(order.orderId).set(order);
    // return this.ordersCollection.doc(order.resId).collection('activeOrders').doc().set(order)
  }

  approveOrder(order: any) {
    return this.restaurantCollection.doc(order.resId).collection('activeOrders').doc(order.orderId).update(order);
  }

  markReady(order: any) {
    return this.restaurantCollection.doc(order.resId).collection('activeOrders').doc(order.orderId).update(order);
  }

  rejectOrder(order: any) {
    return this.restaurantCollection.doc(order.resId).collection('activeOrders').doc(order.orderId).delete();
  }

  newOpenOrder(obj: any) {
    return this.openOrdersCollection.doc().set(obj);
  }

  getOrder(resId: any, orderId: any) {
    return this.restaurantCollection.doc(resId).collection('activeOrders').doc(orderId).get();
  }

  saveNewOrderUser(obj: any) {
    return this.usersCollection.doc('8919174919').collection('activeOrders').doc(obj.orderId).set(obj);
  }

  getUserActiveOrders() {
    return this.usersCollection.doc('8919174919').collection('activeOrders').get();
  }
}
