<div class="bodyBg vw-100" [style.height.px]="screenHeight">
    <div class="mainContainer">
        <div class="topBar w-100 my-4">
            <input class="w-100" placeholder="Search for item" type="text" pInputText [(ngModel)]="searchValue" (input)="filterItems(searchValue)"/>
        </div>
        <div *ngIf="filteredItems.length > 0" class="menuDiv mb-4">
            <p-accordion *ngIf="isMenuLoaded" [multiple]="true" [activeIndex]="activeIndex">
                <ng-container *ngFor="let category of filteredItems">
                    <ng-container *ngIf="category.items?.length > 0">
                        <p-accordionTab [header]="accordionHeader(category.id)">
                            <div class="itemDiv d-flex gap-2 p-2" *ngFor="let item of category.items">
                                <div class="itemDescriptionDiv d-flex flex-column gap-5px">
                                    <span class="topBar">
                                        <img *ngIf="item.itemClassification == 'Veg'"
                                            src="../../../assets/icons/veg-icon.png" alt="" height="15px" width="15px">
                                        <img *ngIf="item.itemClassification == 'Non-Veg'"
                                            src="../../../assets/icons/non-veg-icon.png" alt="" height="15px" width="15px">
                                    </span>
                                    <span class="itemTitle">
                                        {{item.itemName}}
                                    </span>
                                    <span class="itemPrice">
                                        ₹ {{item.itemSellingPrice}} /-
                                    </span>
                                    <span *ngIf="item.itemDescription" class="itemDescription">
                                        {{item.itemDescription}}
                                    </span>
                                </div>
                                <div class="itemImageDiv" *ngIf="item.itemImageUrl && !isInCart(item.itemName)">
                                    <div class="imgHolder" [style.background-image]="imgUrl(item.itemImageUrl)"></div>
                                    <div class="addCartBtnImg" (click)="addToCart(item)">
                                        Add
                                    </div>
                                </div>
                                <div class="itemImageDiv" *ngIf="item.itemImageUrl && isInCart(item.itemName)">
                                    <div class="imgHolder" [style.background-image]="imgUrl(item.itemImageUrl)"></div>
                                    <div class="d-flex align-items-center">
                                        <button type="button" class="btn btn-light" [disabled]="orderDetails && itemQuantityCheck(item.itemName) == 1"
                                        (click)="changeQuantity(item.itemName, 'dec')">
                                        <span *ngIf="item.itemQuantity > 1">-</span>
                                        <span *ngIf="item.itemQuantity == 1">
                                            <i class="pi pi-trash"></i>
                                        </span>
                                    </button>
                                    <span class="mx-2">{{item.itemQuantity}}</span>
                                    <button type="button" class="btn btn-light"
                                        (click)="changeQuantity(item.itemName, 'inc')">+</button>
                                    </div>
                                </div>
                                <div *ngIf="!item.itemImageUrl && !isInCart(item.itemName)" class="itemBtnDiv">
                                    <div class="addCartBtn" (click)="addToCart(item)">
                                        Add
                                    </div>
                                </div>
                                <div *ngIf="!item.itemImageUrl && isInCart(item.itemName)" class="itemBtnDiv">
                                    <div class="d-flex align-items-center">
                                        <button type="button" class="btn btn-light" [disabled]="orderDetails && itemQuantityCheck(item.itemName) == 1" (click)="changeQuantity(item.itemName, 'dec')">
                                            <span *ngIf="itemQuantityCheck(item.itemName) > 1">-</span>
                                            <span *ngIf="itemQuantityCheck(item.itemName) == 1">
                                                <i class="pi pi-trash"></i>
                                            </span>
                                        </button>
                                        <span class="mx-2">{{itemQuantityCheck(item.itemName)}}</span>
                                        <button type="button" class="btn btn-light" (click)="changeQuantity(item.itemName, 'inc')">+</button>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                    </ng-container>
                </ng-container>
            </p-accordion>
        </div>
        <div *ngIf="(filteredItems.length == 0 || !filteredItems) && !isMenuLoaded" class="spinnerDiv">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div *ngIf="(filteredItems.length == 0 || !filteredItems) && isMenuLoaded" class="emptyDiv mb-4">
            <img src="../../../assets/images/sorryItemNotFound.png" alt="">
            <span class="emptyMsg">
                No Items found
            </span>
        </div>
        <div class="makeDiv pb-2">
            Made in INDIA
        </div>
        <div class="luvDiv pb-4">
            Made with <i class="fa-solid fa-heart heartSp"></i>
        </div>
        <div class="copyRightDiv d-flex justify-content-center">
            Copyright &copy; 2024 | aDev
        </div>
        <div class="bottomBar" *ngIf="cartArr.length > 0">
            <div class="viewCartBtn d-flex justify-content-between" (click)="showCartDiv()">
                <span>
                    {{totalItemsCart()}} item added
                </span>
                <span>
                    View Cart <span><i class="pi pi-pi-angle-right"></i></span>
                </span>
            </div>
        </div>
    </div>
</div>
<div class="previewCartDiv" *ngIf="showCart">
    <div class="cartItemsDiv">
        <div class="topBar d-flex justify-content-between align-items-center">
            <span>My Cart</span>
            <span style="cursor: pointer;" (click)="closeCartDiv()"><i class="pi pi-times"></i></span>
        </div>
        <div class="itemsListDiv p-2">
            <div class="cartItemDiv p-2 d-flex gap-2 justify-content-between align-items-start" *ngFor="let item of cartArr">
                <div class="d-flex gap-2">
                    <span>
                        <img *ngIf="item.itemClassification == 'Veg'"
                            src="../../../assets/icons/veg-icon.png" alt="" height="15px" width="15px">
                        <img *ngIf="item.itemClassification == 'Non-Veg'"
                            src="../../../assets/icons/non-veg-icon.png" alt="" height="15px" width="15px">
                    </span>
                    <div class="d-flex flex-column" style="    font-size: 18px;
                    font-weight: 400;">
                        <span>
                            {{item.itemName}}
                        </span>
                        <span style="font-size: 13px;">
                            ₹ {{item.itemSellingPrice}} /-
                        </span>
                    </div>
                </div>
                <div>
                    <div class="d-flex align-items-center">
                        <button type="button" class="btn btn-light" (click)="changeQuantity(item.itemName, 'dec')" [disabled]="orderDetails && itemQuantityCheck(item.itemName) == 1">
                            <span *ngIf="item.itemQuantity > 1">-</span>
                            <span *ngIf="item.itemQuantity == 1">
                                <i class="pi pi-trash"></i>
                            </span>
                        </button>
                        <span class="mx-2">{{item.itemQuantity}}</span>
                        <button type="button" class="btn btn-light" (click)="changeQuantity(item.itemName, 'inc')">+</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottomBar" *ngIf="cartArr.length > 0">
            <div class="w-100 d-flex justify-content-between px-3">
                <span class="cartValueDiv">Total</span>
                <span class="cartValueDiv">₹ {{cartTotal}} /-</span>
            </div>
            <div *ngIf="!orderDetails" class="viewCartBtn d-flex justify-content-center" (click)="placeOrder()">
                Place Order
            </div>
            <div *ngIf="orderDetails" class="viewCartBtn d-flex justify-content-center" (click)="updateOrder()">
                Update Order
            </div>
        </div>
    </div>
</div>