import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent {

  categoriesArr: any[] = []
  itemsArr: any[] = []
  activeCategory: any;
  isAdmin: boolean = true;
  showAddCategory: boolean = false;
  categoryModalHeader: string = '';
  addCategoryForm: FormGroup;
  showAddItem: boolean = false;
  classifications: any[] | undefined;
  addItemForm: FormGroup;
  imageSrc: any = '';
  file: any;

  constructor(private formBuilder: FormBuilder, private appService: AppService, private fireStorage: AngularFireStorage) {
    this.addCategoryForm = this.formBuilder.group({  
      categoryName: ['', Validators.required],
    });
    this.addItemForm = this.formBuilder.group({  
      itemName: ['', Validators.required],
      itemClassification: ['', Validators.required],
      itemDescription: [''],
      itemMakingCost: [null],
      itemSellingPrice: [null, Validators.required],
      inStock: [false, Validators.required],
    });
    this.classifications = [
      { name: 'Veg' },
      { name: 'Non-Veg' },
    ]
  }

  ngOnInit() {
    var timer = setInterval(() => {
      if(this.appService.selectedRestaurantId) {
        clearInterval(timer)
        this.appService.restaurantCollection.doc(this.appService.selectedRestaurantId).collection('menu').valueChanges({idField: 'id'}).subscribe((res: any) => {
          this.categoriesArr = []
          this.categoriesArr.push(...res)
          this.updateActiveCategory(this.categoriesArr[0].id)
        }, err => {
          console.warn(err);
        }, () => {
        })
      }
    }, 1000)
  }

  openAddCategoryModal() {
    this.showAddCategory = true;
    this.categoryModalHeader = 'Add New Category'
  }

  closeAddCategoryModal() {
    this.addCategoryForm.reset();
    this.showAddCategory = false;
  }


  updateActiveCategory(id: any) {
    this.activeCategory = id;
    this.itemsArr = []
    this.appService.getItems(this.appService.selectedRestaurantId, this.activeCategory).subscribe((res: any) => {
      res.forEach((ele: any) => {
        this.itemsArr.push(ele.data())
      });
    })
  }


  saveCategory() {
    var obj = {
      categoryName: this.addCategoryForm.controls['categoryName'].value,
      lastUpdatedTime: new Date().valueOf(),
      lastUpdatedBy: localStorage.getItem('userName')
    }
    this.appService.addNewCategory(this.appService.selectedRestaurantId, obj).then((res: any) => {
      // this.showSuccess("Category added successfully")
    }, (err: any) => {
      console.warn(err);
      
      // this.showError("Error adding new category")
    })
    this.closeAddCategoryModal();
  }

  openAddItemModal() {
    this.showAddItem = true;
  }

  closeAddItemModal() {
    this.showAddItem = false;
  }

  removeImg() {
    this.file = null
    this.imageSrc = ''
  }


  async readURL(event: any) {
    if (event.target.files && event.target.files[0]) {
        this.file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = e => this.imageSrc = reader.result;
        reader.readAsDataURL(this.file);
    }
  }

  async saveItem() {
    var imgUrl = '';
    if(this.file) {
      const path = `onlineMenu/${this.addItemForm.controls['itemName'].value}/${this.file.name}`
      const uploadTask = this.fireStorage.upload(path, this.file)
      const url = (await (await uploadTask).ref.getDownloadURL()).valueOf()
      var timer = setInterval(() => {
        if(url.toString() != '') {
          imgUrl = url;
          var obj = {
            itemName: this.addItemForm.controls['itemName'].value,
            itemClassification: this.addItemForm.controls['itemClassification'].value.name,
            itemDescription: this.addItemForm.controls['itemDescription'].value == '' ? null : this.addItemForm.controls['itemDescription'].value,
            itemMakingCost: this.addItemForm.controls['itemMakingCost'].value,
            itemSellingPrice: this.addItemForm.controls['itemSellingPrice'].value,
            itemImageUrl: imgUrl,
            lastUpdatedTime: new Date().valueOf(),
            lastUpdatedBy: localStorage.getItem('userName'),
            inStock: true
          }
          this.appService.addNewItem(this.appService.selectedRestaurantId, this.activeCategory, obj).then((res: any) => {
            this.addItemForm.reset()
            // this.showSuccess("Item Added Successfully")
            this.closeAddItemModal()
            this.file = null
            this.imageSrc = ''
            this.updateActiveCategory(this.activeCategory)
          }, (err: any) => {
            console.warn(err);
            // this.showError("Error adding item")
            this.closeAddItemModal()
            this.file = null
            this.imageSrc = ''
          })
          clearInterval(timer)
        }
      }, 100)
    } else {
      var obj1 = {
        itemName: this.addItemForm.controls['itemName'].value,
        itemClassification: this.addItemForm.controls['itemClassification'].value.name,
        itemDescription: this.addItemForm.controls['itemDescription'].value == '' ? null : this.addItemForm.controls['itemDescription'].value,
        itemMakingCost: this.addItemForm.controls['itemMakingCost'].value,
        itemSellingPrice: this.addItemForm.controls['itemSellingPrice'].value,
        itemImageUrl: null,
        lastUpdatedTime: new Date().valueOf(),
        lastUpdatedBy: localStorage.getItem('userName'),
        inStock: true
      }
      this.appService.addNewItem(this.appService.selectedRestaurantId, this.activeCategory, obj1).then((res: any) => {
        this.addItemForm.reset()
        // this.showSuccess("Item Added Successfully")
        this.closeAddItemModal()
        this.updateActiveCategory(this.activeCategory)
      }, (err: any) => {
        console.warn(err);
        // this.showError("Error adding item")
        this.closeAddItemModal()
      })
    }
  }
}
