<div class="mainContainer">
    <p-tabMenu styleClass="tabMenu" [model]="items" [activeItem]="activeItem" (activeItemChange)="updateActiveItem($event)"/>
    <div *ngIf="activeItem?.label == 'Preparing'">
         <div class="d-flex flex-column gap-4">
            <!-- <div class="orderCard d-flex flex-column gap-2" *ngFor="let order of dummyData"> -->
            <div class="orderCard d-flex flex-column gap-2" *ngFor="let order of preparingOrders">
                <div class="card">
                    <div class="riderInfo" *ngIf="hasRiderDetails(order)">
                        <div class="info">
                            <span class="titleText">Rider Name:  </span>
                            <span class="text-muted">{{order.riderDetails.riderName}}</span>
                        </div>
                        <div class="info d-flex justify-content-start">
                            <span class="titleText">OTP: 1234</span>
                            <span class="text-muted">{{order.riderDetails.otp}}</span>
                        </div>
                        <div class="info d-flex justify-content-start">
                            <span class="titleText">Mobile:   </span>
                            <span class="text-muted"> {{order.riderDetails.riderMobile}}</span>
                        </div>
                        <div class="info d-flex justify-content-start" *ngIf="order.riderDetails?.riderStatus == 2 && order.riderDetails?.riderArrivalTime">
                            <span class="titleText">Rider has arrived and waiting since {{ order.riderDetails.timeElapsed }}</span>
                        </div>
                        <!-- <div class="timeLine"  *ngFor="let rider of order?.rider">
                            <span class="titleText">Time:  </span>
                            <span class="text-muted">{{rider.riderTime}}</span>
                        </div> -->
                        <div class="d-flex justify-content-end gap-2 mt-4">
                            <p-button class="addBtn" icon="pi pi-phone" severity="primary" label="Call Rider" size="small"></p-button>
                            <p-button class="addBtn" icon="pi pi-map-marker" severity="primary" label="Track" size="small"></p-button>
                        </div>
                    </div>
                    <div class="riderInfo" *ngIf="!hasRiderDetails(order)">
                        Rider has not been assigned
                    </div>
                </div>
                <div class="d-flex gap-2">
                    <span class="titleText">Order Id: </span>
                    <span class="text-muted">{{order.orderId}}</span>
                </div>
                <div class="d-flex gap-2">
                    <span class="titleText">Order Time: </span>
                    <span class="text-muted">{{ formattedOrderTime(order.orderTime) }}</span>
                </div>
                <div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Item Name</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Item Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of order?.items">
                                <td>{{item.itemName}}</td>
                                <td>{{item.itemSellingPrice}}</td>
                                <td>{{item.itemQuantity}}</td>
                                <td>{{item.itemSellingPrice * item.itemQuantity}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex gap-2 justify-content-end">
                        <span class="titleText">Discount: 0</span>
                        <!-- <span class="text-muted">{{item.discount}}</span> -->
                    </div>
                    <div class="d-flex gap-2 justify-content-end">
                        <span class="titleText">Total Bill: {{ calculateTotalBill(order) | currency:'INR':'symbol' }}</span>
                        <!-- <span class="text-muted">{{item.total}}</span> -->
                    </div>
                </div>
                <div class="d-flex justify-content-between gap-2 mt-4">
                    <div>
                    <p-button class="addBtn1" icon="pi pi-print" severity="primary" label="KOT" size="small"></p-button>
                    <p-button class="addBtn1" severity="primary"icon=" pi pi-print" label="Order" size="small"></p-button>
                    </div>
                    <div class="time-container">
                        <p-button class="addBtn" severity="primary" [label]="'Mark Ready '+order.timeLeft" size="small" (click)="markOrderReady(order)"></p-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="activeItem?.label == 'Ready'">
        <div class="d-flex flex-column gap-4">
            <!-- <div class="orderCard d-flex flex-column gap-2" *ngFor="let order of dummyData"> -->
            <div class="orderCard d-flex flex-column gap-2" *ngFor="let order of readyOrders">
                <div class="card">
                    <div class="riderInfo" *ngIf="hasRiderDetails(order)">
                        <div class="info">
                            <span class="titleText">Rider Name:  </span>
                            <span class="text-muted">{{order.riderDetails.riderName}}</span>
                        </div>
                        <div class="info d-flex justify-content-start">
                            <span class="titleText">OTP: 1234</span>
                            <span class="text-muted">{{order.riderDetails.otp}}</span>
                        </div>
                        <div class="info d-flex justify-content-start">
                            <span class="titleText">Mobile:   </span>
                            <span class="text-muted"> {{order.riderDetails.riderMobile}}</span>
                        </div>
                        <div class="info d-flex justify-content-start" *ngIf="order.riderDetails?.riderStatus == 2 && order.riderDetails?.riderArrivalTime">
                            <span class="titleText">Rider has arrived and waiting since {{ order.riderDetails.timeElapsed }}</span>
                        </div>
                        <!-- <div class="timeLine"  *ngFor="let rider of order?.rider">
                            <span class="titleText">Time:  </span>
                            <span class="text-muted">{{rider.riderTime}}</span>
                        </div> -->
                        <div class="d-flex justify-content-end gap-2 mt-4">
                            <p-button class="addBtn" icon="pi pi-phone" severity="primary" label="Call Rider" size="small"></p-button>
                            <p-button class="addBtn" icon="pi pi-map-marker" severity="primary" label="Track" size="small"></p-button>
                        </div>
                    </div>
                    <div class="riderInfo" *ngIf="!hasRiderDetails(order)">
                        Rider has not been assigned
                    </div>
                </div>
                <div class="d-flex gap-2">
                    <span class="titleText">Order Id: </span>
                    <span class="text-muted">{{order.orderId}}</span>
                </div>
                <div class="d-flex gap-2">
                    <span class="titleText">Order Time: </span>
                    <span class="text-muted">{{ formattedOrderTime(order.orderTime) }}</span>
                </div>
                <div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Item Name</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Item Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of order?.items">
                                <td>{{item.itemName}}</td>
                                <td>{{item.itemSellingPrice}}</td>
                                <td>{{item.itemQuantity}}</td>
                                <td>{{item.itemTotalPrice}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex gap-2 justify-content-end">
                        <span class="titleText">Discount:</span>
                        <!-- <span class="text-muted">{{item.discount}}</span> -->
                    </div>
                    <div class="d-flex gap-2 justify-content-end">
                        <span class="titleText">Total Bill:</span>
                        <!-- <span class="text-muted">{{item.total}}</span> -->
                    </div>
                </div>
                <!-- <div class="handover">
                   Handover Now (00:00)
                </div>
                <div class="d-flex justify-content-start gap-2 mt-4">
                    <p-button class="addBtn" severity="primary"icon=" pi pi-print" label="Order" size="small"></p-button>
                </div> -->
                <div class="d-flex justify-content-between gap-2 mt-4">
                    <div>
                    <p-button class="addBtn1" icon="pi pi-print" severity="primary" label="KOT" size="small"></p-button>
                    <p-button class="addBtn1" severity="primary"icon=" pi pi-print" label="Order" size="small"></p-button>
                    </div>
                    <div class="time-container">
                        <p-button class="addBtn" *ngIf="order.handOverTimeLeft == 'Handover Time Exceeded'" severity="primary" [label]="order.handOverTimeLeft" size="small"></p-button>
                        <p-button class="addBtn" *ngIf="order.handOverTimeLeft != 'Handover Time Exceeded'"  severity="primary" [label]="'Mark Ready '+order.handOverTimeLeft" size="small"></p-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="activeItem?.label == 'Picked Up'">
        <div class="d-flex flex-column gap-4">
            <!-- <div class="orderCard d-flex flex-column gap-2" *ngFor="let order of dummyData"> -->
            <div class="orderCard d-flex flex-column gap-2" *ngFor="let order of pickedUpOrders">
                <div class="card">
                    <div class="riderInfo" *ngFor="let rider of order?.rider">
                        <div class="info">
                            <span class="titleText">Rider Name:  </span>
                            <span class="text-muted">{{rider.riderName}}</span>
                            <div class="verified">
                                <span class="titleText">Picked Up <i class="pi pi-check-circle" style="font-weight: bolder; color: green;"></i></span>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="d-flex gap-2">
                    <span class="titleText">Order Id: </span>
                    <span class="text-muted">{{order.orderId}}</span>
                </div>
                <div class="d-flex gap-2">
                    <span class="titleText">Order Time: </span>
                    <span class="text-muted">{{ formattedOrderTime(order.orderTime) }}</span>
                </div>
                <div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Item Name</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Item Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of order?.items">
                                <td>{{item.itemName}}</td>
                                <td>{{item.itemSellingPrice}}</td>
                                <td>{{item.itemQuantity}}</td>
                                <td>{{item.itemTotalPrice}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex gap-2 justify-content-end" *ngFor="let item of order?.items">
                        <span class="titleText">Discount:</span>
                        <span class="text-muted">{{item.discount}}</span>
                    </div>
                    <div class="d-flex gap-2 justify-content-end" *ngFor="let item of order?.items">
                        <span class="titleText">Total Bill:</span>
                        <span class="text-muted">{{item.total}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="activeItem?.label == 'New'">
        <!-- <p-button class="addBtn" severity="primary" [label]="'Get Order Details'" size="small" (click)="loadMap()"></p-button> -->
        <!-- <app-google-map [origin]="origin" [destination]="destination"></app-google-map> -->
    </div>
</div>

<p-dialog header="New Order" [(visible)]="showNewOrder" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false"
    [resizable]="false">
    <div class="m-0">
        <div class="d-flex flex-column">
            <!-- <div class="orderCard d-flex flex-column gap-2" *ngFor="let order of dummyData"> -->
            <div class="orderCard d-flex flex-column gap-2" *ngFor="let order of newOrders">
                <div class="d-flex gap-2">
                    <span class="titleText">Order Id: </span>
                    <span class="text-muted">{{order.orderId}}</span>
                </div>
                <div class="d-flex gap-2">
                    <span class="titleText">Order Time: </span>
                    <span class="text-muted">{{ formattedOrderTime(order.orderTime) }}</span>
                </div>
                <div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Item Name</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of order?.items">
                                <td>{{item.itemName}}</td>
                                <td>{{item.itemSellingPrice}}</td>
                                <td>{{item.itemQuantity}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <span class="titleText">Adjust Timer: ( in minutes )</span>
                <div class="input-button-group"> 
                    <div class="d-flex justify-content-center align-items-center gap-4 my-2">
                        <button pButton type="button" icon="pi pi-minus" class="p-button-rounded" (click)="decreaseTimer()"></button>
                      
                        <input type="text" pInputText [(ngModel)]="timerValue" class="w-full" />
                      
                        <button pButton type="button" icon="pi pi-plus" class="p-button-rounded" (click)="increaseTimer()"></button>
                    </div>
                  
                    <p-slider [(ngModel)]="timerValue" [min]="12" [max]="25" class="w-full"></p-slider>
                </div>
                <div class="d-flex justify-content-end gap-2 mt-4">
                    <p-button class="addBtn" [outlined]="true" severity="danger" label="Reject" size="small"
                        (onClick)="rejectOrder(order)"></p-button>
                    <p-button class="addBtn" severity="primary" [label]="'Approve '+countdown" size="small" (click)="approveOrder(order)"></p-button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>