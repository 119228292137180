import { Component, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from '../../../app.service';
import { RazorpayService } from '../../../razorpay.service';

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrl: './menu-card.component.scss'
})
export class MenuCardComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  searchValue: any;
  isMenuLoaded: boolean = false;
  activeIndex: any[] = [];
  categoriesArr: any[] = [];
  cartArr: any[] = [];
  filteredItems: any[] = [];
  showCart = false;
  cartTotal = 0;
  orderDetails: any;
  resId: any;
  user: any = {
    email: 'abhinav.chinta@adev.co.in',
    mobile: '+918919174919',
    displayName: 'Abhinav Chinta'
  }
  userLong: number = 0;
  userLat: number = 0;
  restaurantLocation: any;
  constructor(private sanitizer: DomSanitizer, private appService: AppService, private razorpayService: RazorpayService) {
    this.resId = localStorage.getItem('selectedRes');
    this.getCurrentLocation()
    this.getScreenSize();
    this.appService.getActiveRestaurantDetails(this.resId).subscribe((res: any) => {
      this.restaurantLocation = res.data().restaurantLocation
    })
    this.appService.restaurantCollection.doc(this.resId).collection('menu').valueChanges({idField: 'id'}).subscribe((res: any) => {
      this.categoriesArr = []
      this.categoriesArr.push(...res)
      for (let index = 0; index < this.categoriesArr.length; index++) {
        this.appService.getItems(this.resId, this.categoriesArr[index].id).subscribe((menuItems: any) => {
          var itemsArr: any[] = []
          menuItems.forEach((ele: any) => {
            itemsArr.push(ele.data())
          });
          this.categoriesArr[index]['items'] = itemsArr;
          this.filteredItems = this.categoriesArr.reduce((filtered, category) => {
            const filteredItems = category.items?.filter((item: any) => item?.inStock);
            if (filteredItems?.length) {
                filtered.push({ ...category, items: filteredItems });
            }
            return filtered;
        }, []);     
        this.isMenuLoaded = true;
          setTimeout(() => {
            this.activeIndex = [0];
          }, 100);
        })
      }
    }, (err: any) => {
      console.warn(err);
    }, () => {
      console.warn(this.categoriesArr);
    })
  }

  filterItems(itemName: string) {
    this.filteredItems = this.categoriesArr.reduce((acc, category) => {
      const filteredItems = category.items?.filter((item: any) =>
        item.itemName.toLowerCase().includes(itemName.toLowerCase()) && item.inStock
      );
      if (filteredItems.length > 0) {
        acc.push({ ...category, items: filteredItems });
      }
      return acc;
    }, []);
    this.isMenuLoaded = true;
    this.activeIndex = [0];
  }

  accordionHeader(header: any) {
    const vegSidesIndex = this.filteredItems?.findIndex(item => item.id === header);
    var title = header + '\t(' + this.filteredItems[vegSidesIndex].items.length + ')'
    return title
  }

  isInCart(itemName: any) {
    if(this.cartArr.length > 0) {
      const index = this.cartArr?.findIndex(item => item.itemName === itemName);
      return index != -1 ? true : false;
    } else {
      return false;
    }
  }

  changeQuantity(item: any, operator: any) {
    const index = this.cartArr?.findIndex(menuItem => menuItem.itemName === item);
    if(operator == 'inc') {
      this.cartArr[index]['itemQuantity'] = this.cartArr[index]['itemQuantity'] + 1;
    } else {
      this.cartArr[index]['itemQuantity'] = this.cartArr[index]['itemQuantity'] - 1;
      if(this.cartArr[index]['itemQuantity'] == 0) {
        this.cartArr.splice(index, 1);
      }
    }
    if(this.cartArr.length == 0) {
      this.closeCartDiv();
    }
    this.getCartTotal()
  }

  closeCartDiv() {
    this.showCart = false;
  }

  getCartTotal() {
    this.cartTotal = 0;
    this.cartArr.forEach(element => {
      this.cartTotal = this.cartTotal + element.itemQuantity * element.itemSellingPrice;
    });
  }

  itemQuantityCheck(itemName: any): number {
    if(this.cartArr.length > 0) {
      const index = this.cartArr?.findIndex((item: any) => item.itemName === itemName);
      return index != -1 ? this.cartArr[index]['itemQuantity'] : 0;
    } else {
      return 0;
    }
  }

  addToCart(item: any) {
    item['itemQuantity'] = 1
    this.cartArr.push(item);
  }


  imgUrl(imgUrl: any) {
    var url = this.sanitizer.bypassSecurityTrustStyle(`url('${imgUrl}')`);
    return url
  }

  showCartDiv() {
    this.showCart = true;
    this.getCartTotal();
  }

  totalItemsCart(): number {
    var quantity = 0;
    this.cartArr.forEach(element => {
      quantity += element.itemQuantity
    });
    return quantity
  }

  placeOrder() {
    // this.initiatePayment()
    var obj = {
      name: this.user.displayName,
      email: this.user.email,
      mobile: this.user.mobile,
      items: this.cartArr,
      userLocation: {
        userLat: this.userLat,
        userLong: this.userLong
      },
      restaurantLocation: this.restaurantLocation,
      orderStatus: 'New',
      resId: this.resId,
      orderId: this.generateRandomFourDigitNumber().toString(),
      orderTime: Date.now()
    }
    this.appService.saveNewOrder(obj).then((res) => {
      this.showCart = false;
    })
    this.appService.saveNewOrderUser(obj)
  }

  generateRandomFourDigitNumber(): number {
    const min = 1000; // Smallest 4-digit number
    const max = 9999; // Largest 4-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  initiatePayment() {
    var obj = {
      name: this.user.displayName,
      email: this.user.email,
      mobile: this.user.mobile,
      userLocation: {
        userLat: this.userLat,
        userLong: this.userLong
      }
    }
    const amount = this.cartTotal * 100; // Amount in currency subunits. Here 1000 means 10.00 INR
    const key = 'rzp_live_0pr1DoN6E38yAo'; // Replace with your actual API key
    this.razorpayService.payWithRazorpay(100, key, obj)
    .then((success: any) => {
      console.warn(success);
      
      if (success) {
        console.log('Payment was successful');
        // Further actions for successful payment
      }
    })
    .catch((error: any) => {
      if (!error) {
        console.log('Payment failed');
        // Further actions for failed payment
      }
    });  
  }

  async getCurrentLocation() {
    const permission = await navigator.permissions.query({ name: 'geolocation' });
    if (permission.state === 'granted') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.userLong = position.coords.longitude
            this.userLat = position.coords.latitude
          },
          (error) => {
            console.error("Error getting location:", error.message);
            setTimeout(() => {
              this.getCurrentLocation()
              console.warn('Retrying');
            }, 4000);
          },
          { enableHighAccuracy: true, timeout: 4000 }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    } else if (permission.state === 'prompt') {
      const newPermission = await navigator.geolocation.getCurrentPosition(() => {
        console.warn('success');
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.userLong = position.coords.longitude
              this.userLat = position.coords.latitude
            },
            (error) => {
              console.error("Error getting location:", error.message);
              setTimeout(() => {
                this.getCurrentLocation()
                console.warn('Retrying');
              }, 4000);
            },
            { enableHighAccuracy: true, timeout: 4000 }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }

      }
      );
    } else {
      console.error("Location permission denied.");
    }
  }

  updateOrder() {

  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        if(this.screenWidth < 768) {
          this.isDesktop = false;
          this.isMobile = true;
        } else {
          this.isDesktop = true;
          this.isMobile = false;
        }
  }
}
