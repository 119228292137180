import { Component, HostListener } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AppService } from '../../../app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  showSideBar: boolean = false;
  user: any;
  userName: string | null | undefined;
  userEmail: string | null | undefined;
  activeRestaurant: string = '';
  restaurantName: string = '';
  showUpdateTimingsModal: boolean = false;

  constructor(private appService: AppService, private router: Router) {
    this.getScreenSize()
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.userName = this.user.displayName
        this.userEmail = user.email
        this.getOutletInfo()
      } else {
        // User not logged in or has just logged out.
      }
    });
  }

  getOutletInfo() {
    this.appService.getRestaurantOwnerDetails(this.user.uid).subscribe((res: any) => {
      this.activeRestaurant = res.data().registeredRestaurants[0];
      this.appService.selectedRestaurantId = this.activeRestaurant
      this.appService.getActiveRestaurantDetails(this.activeRestaurant).subscribe((res: any) => {
        this.restaurantName = res.data().restaurantName;
        if(!res.data().restaurantTimings) {
          this.toggleUpdateTimings()
        }
      })
    })
  }

  toggleShowBar() {
    this.showSideBar = !this.showSideBar;
  }

  logOut() {
    // this.appService.signOut().then(() => {
    //   this.router.navigate(['/']);
    // })
  }

  toggleUpdateTimings() {
    this.showUpdateTimingsModal = !this.showUpdateTimingsModal;
  }

  updateTimings() {
    this.toggleUpdateTimings();
    this.router.navigateByUrl('dashboard/settings')
  }

  updateScreen(screenName: string) {
    this.router.navigateByUrl(`dashboard/${screenName}`)
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        if(this.screenWidth < 768) {
          this.isDesktop = false;
          this.isMobile = true;
        } else {
          this.isDesktop = true;
          this.isMobile = false;
        }
  }
  
}
