<div class="mainContainer vw-100 d-flex flex-column align-items-center justify-content-center gap-4"
    [style.height.px]="screenHeight">
    <div class="timeLineHolder">
        <div class="timeline">
            <div *ngFor="let step of steps; let i = index" class="step">
                <div class="circle" [ngClass]="step.status">
                    <span *ngIf="step.status !== 'not-started'">{{ i + 1 }}</span>
                </div>
                <span class="label">{{ step.title }}</span>
                <div *ngIf="!isLastStep(step)" class="connector"></div>
            </div>
        </div>
    </div>
    <div class="cardHolder d-flex align-items-start justify-content-between flex-column gap-4">
        <div *ngIf="activeStep == 1" class="d-flex flex-column w-100">
            <h4>User Details</h4>
            <hr class="w-100">
            <div class="d-flex flex-column w-100 gap-4 mt-4">
                <div class="row">
                    <div class="col">
                        <div class="d-flex flex-column gap-2">
                            <label for="username">First Name</label>
                            <input pInputText id="username" [(ngModel)]="firstName" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="d-flex flex-column gap-2">
                            <label for="username">Last Name</label>
                            <input pInputText id="username" [(ngModel)]="lastName" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="d-flex flex-column gap-2">
                            <label for="username">Mobile Number</label>
                            <input pInputText id="username" [(ngModel)]="userMobile" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="d-flex flex-column gap-2">
                            <label for="username">Email</label>
                            <input pInputText id="username" [(ngModel)]="userEmail" disabled="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="activeStep == 2" class="d-flex flex-column w-100">
            <h4>User Verification</h4>
            <hr class="w-100">
            <div class="d-flex flex-column w-100 gap-4 mt-4">
                <p-fileUpload mode="basic" chooseLabel="Choose" chooseIcon="pi pi-upload"
                    accept="image/*,.pdf" 
                    #fileUpload/>
                    <!-- <div class="d-flex flex-column gap-2">
                        <label for="username">Enter Amount</label>
                        <input pInputText id="username" [(ngModel)]="billAmount" />
                    </div>
            <p-button label="PAY" icon="pi pi-angle-right" severity="primary" iconPos="right" (click)="initiatePayment()"/> -->
            </div>
        </div>
        <div *ngIf="activeStep == 3" class="d-flex flex-column w-100" style="overflow: scroll;">
            <h4>Restaurant Details</h4>
            <hr class="w-100">
            <div class="d-flex flex-column w-100 gap-4 mt-3">
                <div class="row">
                    <div class="col">
                        <div class="d-flex flex-column gap-2">
                            <label for="restaurantName">Restaurant Name</label>
                            <input pInputText id="restaurantName" [(ngModel)]="restaurantName" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="d-flex flex-column gap-2">
                            <label for="restaurantName">Restaurant Type</label>
                            <input pInputText id="restaurantName" [(ngModel)]="restaurantType" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="d-flex flex-column gap-2">
                            <label for="restaurantName">Owner Contact Number</label>
                            <input pInputText id="restaurantName" disabled="true" [(ngModel)]="ownerNumber" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="d-flex flex-column gap-2">
                            <label for="restaurantName">Restaurant Contact Number</label>
                            <input pInputText id="restaurantName" [(ngModel)]="restaurantNumber" />
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <h5>Location <span class="ml-4" style="cursor: pointer;" (click)="getCurrentLocation()"><i class="pi pi-map-marker"></i></span></h5>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="d-flex flex-column gap-2">
                            <label for="restaurantName">Longitude</label>
                            <input pInputText id="restaurantName" [disabled]="restaurantLong == 0" [(ngModel)]="restaurantLong" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="d-flex flex-column gap-2">
                            <label for="restaurantName">Latitude</label>
                            <input pInputText id="restaurantName" [disabled]="restaurantLat == 0" [(ngModel)]="restaurantLat" />
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="restaurantLat && restaurantLong" >
                    <app-google-map
                    [lat]="restaurantLat" 
                    [lng]="restaurantLong"
                    (locationChanged)="updateLocation($event)">
                    </app-google-map>
                </div>
            </div>
        </div>
        <div *ngIf="activeStep == 4" class="d-flex flex-column w-100">
            <h4>Restaurant Verification</h4>
            <hr class="w-100">
            <div class="d-flex flex-column w-100 gap-4 mt-4">
                <p-fileUpload mode="basic" chooseLabel="Choose" chooseIcon="pi pi-upload"
                    accept="image/*,.pdf" 
                    #fileUpload/>
                    <!-- <div class="d-flex flex-column gap-2">
                        <label for="username">Enter Amount</label>
                        <input pInputText id="username" [(ngModel)]="billAmount" />
                    </div>
            <p-button label="PAY" icon="pi pi-angle-right" severity="primary" iconPos="right" (click)="initiatePayment()"/> -->
            </div>
        </div>
        <div *ngIf="activeStep == 5" class="d-flex flex-column w-100">
            <h4>Onboarding Fees</h4>
            <hr class="w-100">
            <div class="d-flex flex-column w-100 gap-4 mt-4">
                <p-button label="PAY" icon="pi pi-angle-right" severity="primary" iconPos="right" (click)="initiatePayment()"/>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-end gap-4" *ngIf="activeStep != 5">
            <p-button label="Save" icon="pi pi-save" severity="secondary" iconPos="right" (click)="saveCurrentStep()" />
            <p-button label="Next" icon="pi pi-angle-right" severity="primary" iconPos="right" />
        </div>
    </div>
</div>

<p-dialog header="Application Status" [closable]="false" [modal]="true" [(visible)]="successModal" [style]="{ width: '25rem' }">
    <span class="p-text-secondary block mb-5">Payment received. Verification In Progress</span>
</p-dialog>