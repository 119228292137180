<div class="mainContainer">
    <div class="menuCompDiv d-flex">
        <div class="categorySlider">
            <div class="topBar categoryBar">
                <span>
                    Menu Categories
                </span>
                <span>
                    ({{categoriesArr.length}})
                </span>
            </div>
            <div class="addCategoryDiv d-flex align-items-center" (click)="openAddCategoryModal()">
                <div class="addIcon">
                    +
                </div>
                <div class="addBtnText">
                    Add Category
                </div>
            </div>
            <div class="categoryDiv d-flex justify-content-between w-100"
                *ngFor="let category of categoriesArr; index as i">
                <span class="w-100" (click)="updateActiveCategory(category.id)">
                    {{category.id}}
                </span>
                <div class="d-flex gap-3">
                    <span class="actionBtn">
                        <i class="pi pi-info-circle"></i>
                    </span>
                    <span *ngIf="isAdmin" class="actionBtn">
                        <i class="pi pi-trash"></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="itemSlider">
            <div class="topBar">
                <span>
                    {{activeCategory}}
                </span>
                <span>
                    ({{itemsArr.length}})
                </span>
            </div>
            <div class="addItemDiv d-flex align-items-center" (click)="openAddItemModal()">
                <div class="addIcon">
                    +
                </div>
                <div class="addBtnText">
                    Add Item
                </div>
            </div>
            <div class="itemDiv d-flex justify-content-between" *ngFor="let item of itemsArr; index as i">
                <span>
                    {{item.itemName}}
                </span>
                <div class="d-flex gap-3">
                    <span>
                        <i class="pi pi-info-circle"></i>
                    </span>
                    <span *ngIf="isAdmin">
                        <i class="pi pi-trash"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Add New Category" [(visible)]="showAddCategory" [modal]="true" (onHide)="closeAddCategoryModal()"
    [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <form action="submit" [formGroup]="addCategoryForm">
        <div class="row w-50">
            <div class="col">
                <label for="categoryName" class="form-label">Category Name</label>
                <input type="text" formControlName="categoryName" class="form-control">
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button class="addBtn" label="Cancel" [outlined]="true" severity="secondary" size="small"
            (onClick)="closeAddCategoryModal()"></p-button>
        <p-button class="addBtn" label="Save Changes" [disabled]="!addCategoryForm.valid" [outlined]="false"
            severity="primary" size="small" (onClick)="saveCategory()"></p-button>
    </div>
</p-dialog>

<p-dialog header="Add New Item" [(visible)]="showAddItem" [modal]="true" (onHide)="closeAddItemModal()"
    [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <form action="submit" [formGroup]="addItemForm" class="d-flex flex-column gap-3">
        <div class="row">
            <div class="col">
                <label for="itemName" class="form-label">Item Name</label>
                <input type="text" formControlName="itemName" class="form-control" placeholder="Mint Mocktail">
            </div>
            <div class="col d-flex flex-column">
                <label for="itemClassification" class="form-label">Item Classification</label>
                <p-dropdown [options]="classifications" formControlName="itemClassification" optionLabel="name"
                    placeholder="Select a Classification"></p-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex flex-column">
                <label for="itemDescription" class="form-label">Item Description</label>
                <textarea rows="5" cols="30" pInputTextarea formControlName="itemDescription"
                    placeholder="Item Description : A fresh blend of lime along with......"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label for="itemMakingCost" class="form-label">Item Making Cost</label>
                <input type="number" formControlName="itemMakingCost" class="form-control" placeholder="0">
            </div>
            <div class="col">
                <label for="itemSellingPrice" class="form-label">Item Selling Price</label>
                <input type="number" formControlName="itemSellingPrice" class="form-control" placeholder="0">
            </div>
        </div>
        <div class="row justify-content-center">
            <label for="itemSellingPrice" class="form-label d-flex justify-content-between">Item Image <span
                    style="cursor: pointer;" *ngIf="imageSrc != ''" (click)="removeImg()">
                    <i class="pi pi-trash"></i>
                </span></label>
            <div class="imgHolder">
                <input *ngIf="imageSrc == ''" type='file' (change)="readURL($event);" />
                <img *ngIf="imageSrc != ''" height="inherit" width="inherit"
                    [src]="imageSrc || 'http://placehold.it/180'" alt="your image" />
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button class="addBtn" label="Cancel" [outlined]="true" severity="secondary" size="small"
            (onClick)="closeAddItemModal()"></p-button>
        <p-button class="addBtn" label="Save Changes" [disabled]="!addItemForm.valid" [outlined]="false"
            severity="primary" size="small" (onClick)="saveItem()"></p-button>
    </div>
</p-dialog>