import { Injectable } from '@angular/core';

declare var Razorpay: any;

@Injectable({
  providedIn: 'root'
})
export class RazorpayService {

  constructor() { }


  payWithRazorpay(amount: number, key: string, customerDetails: any): Promise<{success: boolean, response?: any}> {
    return new Promise((resolve, reject) => {
      const options = {
        key: key,
        amount: amount.toString(), // Amount is in currency subunits. Convert to string.
        currency: 'INR',
        name: 'aDev',
        description: 'One time onabording fees.',
        handler: (response: any) => {
          // Handle successful payment
          resolve({success: true, response: response});
          // resolve(true);
        },
        prefill: {
          name: customerDetails.name,
          email: customerDetails.email,
          contact: customerDetails.mobile
        },
        theme: {
          color: '#3399cc'
        }
      };

      const rzp1 = new Razorpay(options);
      rzp1.on('payment.failed', (response: any) => {
        // Handle failed payment
        reject(false);
      });

      rzp1.open();
    });
  }
}
