import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './components/onBoarding/landing-page/landing-page.component';
import { RegistrationComponent } from './components/onBoarding/registration/registration.component';
import { DashboardComponent } from './components/partner/dashboard/dashboard.component';
import { OrdersComponent } from './components/partner/orders/orders.component';
import { SettingsComponent } from './components/partner/settings/settings.component';
import { UserLandingPageComponent } from './components/user/user-landing-page/user-landing-page.component';
import { MenuComponent } from './components/partner/menu/menu.component';
import { MenuCardComponent } from './components/user/menu-card/menu-card.component';

const routes: Routes = [
  {path: '', redirectTo: 'onboard', pathMatch: 'full'},
  {path: 'onboard', component: LandingPageComponent},
  {path: 'registration', component: RegistrationComponent},
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'orders', pathMatch: 'full' },
      { path: 'orders', component: OrdersComponent },
      { path: 'menu', component: MenuComponent },
      { path: 'settings', component: SettingsComponent },
    ]
  },
  {
    path: 'user', 
    component: UserLandingPageComponent,
  },
  { path: 'menuCard', component: MenuCardComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
