import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap, MapDirectionsRenderer, MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrl: './google-map.component.scss'
})
export class GoogleMapComponent {
  @Input() lat!: number;
  @Input() lng!: number;
  @Output() locationChanged = new EventEmitter<{ lat: number, lng: number }>();
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild(MapMarker) marker!: any;
  @Input() origin: google.maps.LatLngLiteral | undefined | any;
  @Input() destination: google.maps.LatLngLiteral | undefined | any;
  @ViewChild(MapDirectionsRenderer, { static: false }) directionsRenderer: google.maps.DirectionsRenderer = new google.maps.DirectionsRenderer({ suppressMarkers: true });
  // @ViewChild(MapDirectionsRenderer, { static: false }) directionsRenderer!: MapDirectionsRenderer;
  directionsResults: google.maps.DirectionsResult | undefined | null | any;
  travelTime: string = '';
  travelDistance: string = '';
  mapStyles: google.maps.MapTypeStyle[] = [
    {
      featureType: 'poi', // Hide points of interest (businesses, etc.)
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit', // Hide transit stations and labels
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    // Add more style rules as needed
  ];

  mapOptions: google.maps.MapOptions = {
    zoom: 17,
    fullscreenControl: false,
    streetViewControl: false,
    // disableDefaultUI: true
    scrollwheel: false,
    styles: this.mapStyles
  };

  originMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/images/restaurant-building.png', // Path to your origin marker image
      scaledSize: new google.maps.Size(40, 40) // Adjust size as needed
    },
    label: null,
    // visible: false
    zIndex: 999
  };

  destinationMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/images/destination_marker.png', // Path to your destination marker image
      scaledSize: new google.maps.Size(40, 40) // Adjust size as needed
    },
  };

  markerOptions: google.maps.MarkerOptions = { draggable: true };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['origin'] && this.origin && this.destination) {
      this.calculateAndDisplayRoute();
    }
  }

  ngAfterViewInit() { 
    if (this.lat && this.lng) {
      this.map.googleMap?.setCenter({ lat: this.origin.lat, lng: this.origin.lng }); // Use optional chaining
    }
    var ele = document.getElementsByClassName('map-container')[1] as HTMLElement
    console.warn(ele);
    ele.style.width = '100%'
    ele.style.height = '60%'
    ele.style.borderRadius = '10px'
    ele.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'
  }
  
  calculateAndDisplayRoute() {
    const directionsService = new google.maps.DirectionsService();
  
    if (this.origin && this.destination) {
      directionsService.route(
        {
          origin: this.origin,
          destination: this.destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response: any, status) => {
          try {
            if (status === 'OK') {
              this.directionsResults = response;
              const route = response.routes[0];
              const duration = route.legs.reduce((acc: any, leg: any) => acc + leg.duration!.value, 0);
              const distance = route.legs.reduce((acc: any, leg: any) => acc + leg.distance!.value, 0);
              const hours = Math.floor(duration / 3600);
              const minutes = Math.floor((duration % 3600) / 60);
              this.travelTime = `${hours} hours ${minutes} minutes`;
              this.travelDistance = `${(distance / 1000).toFixed(2)} km`; // Convert meters to kilometers
              console.warn(this.travelTime);
              if(distance < 100) {
                console.warn('Delivery partner reached');
              } else {
                console.warn(this.travelDistance);
              }
            } else {
              console.error('Directions request failed due to ' + status);
            }
        } catch (error) {
            console.error('Error handling directions:', error);
            // Handle the error (e.g., display an error message to the user)
        }
          
        }
      );
    }
    
  }

  onMapClick(event: any) {
    this.marker.setPosition(event.latLng!); // Update marker position
    this.locationChanged.emit({
      lat: event.latLng!.lat(),
      lng: event.latLng!.lng(),
    });
  }

  onMarkerDragEnd(event: any, marker?: any) {
    this.locationChanged.emit({ lat: event.latLng!.lat(), lng: event.latLng!.lng() });
  }
}
