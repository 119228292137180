export const environment = {
    production: false,
    environmentMode: 'DEV',
    firebaseConfig: {
        apiKey: "AIzaSyC9HUlYqcuCO1Jrt5eB9k-_opUtgBEzC40",
        authDomain: "adev-qa.firebaseapp.com",
        projectId: "adev-qa",
        storageBucket: "adev-qa.appspot.com",
        messagingSenderId: "51130483010",
        appId: "1:51130483010:web:8c6bd430bc9afe820348db"
    },
    showEnvironment: true,
}