import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { AppService } from '../../../app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-landing-page',
  templateUrl: './user-landing-page.component.html',
  styleUrl: './user-landing-page.component.scss'
})
export class UserLandingPageComponent {


  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  showSideBar: boolean = false;
  restaurantsArr: any[] = [];
  placeholders = ["Search for a restaurant", "Fruitify"];
  currentPlaceholder = this.placeholders[0];
  placeholderInterval: any; 
  userActiveOrders: any[] = [];
  showOrderDetails: boolean = false;
  origin: google.maps.LatLngLiteral | any = { };
  destination: google.maps.LatLngLiteral | any = { };
  activeOrderRestaurant: any;
  @ViewChild('mapHolder', { static: false }) gmap: ElementRef | any;
  map: google.maps.Map | undefined;
  originMarker: google.maps.Marker | undefined;
  destMarker: google.maps.Marker | undefined;
  constructor(private appService: AppService, private router: Router) {
    this.getScreenSize()
    this.appService.getRestaurantsList().subscribe((res: any) => {
      res.forEach((restaurant: any) => {
        if(restaurant.data().isOnline) {
          this.restaurantsArr.push(restaurant.data())
        }
      });
    })
  }

  ngOnInit() {
    this.startPlaceholderAnimation();
    this.appService.getUserActiveOrders().subscribe((res: any) => {
      res.forEach((ele: any) => {
        this.userActiveOrders.push(ele.data())
      });
    }, err => {

    }, () => {
      this.activeOrderRestaurant = this.findRestaurantById(this.userActiveOrders[0].resId)
    })
    const mapOptions: google.maps.MapOptions = {
      // center: new google.maps.LatLng(37.7749, -122.4194), // Default center
      zoom: 12 
    };
    this.map = new google.maps.Map(this.gmap?.nativeElement, mapOptions);
    this.originMarker = new google.maps.Marker({
      position: new google.maps.LatLng(37.7833, -122.4167),
      map: this.map,
      title: 'Origin'
    });
  
    // Create the destination marker
    this.destMarker = new google.maps.Marker({
      position: new google.maps.LatLng(37.7650, -122.4433),
      map: this.map,
      title: 'Destination'
    });
  }

  findRestaurantById(restaurantId: string) {
    return this.restaurantsArr.find(restaurant => restaurant.restaurantId === restaurantId);
  }

  startPlaceholderAnimation() {
    let index = 0;
    this.placeholderInterval = setInterval(() => {
      // Toggle placeholder-hidden class to control opacity
      const inputElement = document.querySelector('.p-inputtext') as HTMLElement;
      inputElement.classList.add('placeholder-hidden'); 
      setTimeout(() => {
        index = (index + 1) % this.placeholders.length;
        this.currentPlaceholder = this.placeholders[index];
        inputElement.classList.remove('placeholder-hidden'); 
      }, 300); // Small delay for smooth transition
    }, 3000);
  }

  openRestaurant(resId: string) {
    localStorage.setItem('selectedRes', resId);
    this.router.navigateByUrl('menuCard')
  }

  toggleShowBar() {
    this.showSideBar = !this.showSideBar;
  }

  ngOnDestroy() {
    clearInterval(this.placeholderInterval); // Clear interval when component is destroyed
  }

  formattedFoodItems(cuisinesArr: string[]): string {
    return cuisinesArr.join(' • ');
  }

  toggleOrderDetails() {
    this.showOrderDetails = !this.showOrderDetails
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        if(this.screenWidth < 768) {
          this.isDesktop = false;
          this.isMobile = true;
        } else {
          this.isDesktop = true;
          this.isMobile = false;
        }
  }
}
