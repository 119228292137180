import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppService } from '../../../app.service';
import { formatDate } from '@angular/common';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss'
})
export class OrdersComponent {

  items: MenuItem[] | undefined;
  activeItem: any;
  showNewOrder: boolean = false;
  dummyData: any = [
    {
        "email": "abhinav.chinta@adev.co.in",
        "name": "Abhinav Chinta",
        "resId": "123456",
        "mobile": "+918919174919",
        "restaurantLocation": {
            "restaurantLong": 81.0883096,
            "restaurantLat": 16.7190833
        },
        "orderId": "1234",
        "items": [
            {
                "itemDescription": null,
                "itemImageUrl": null,
                "itemQuantity": 1,
                "lastUpdatedBy": null,
                "itemName": "French Fries",
                "itemMakingCost": null,
                "itemSellingPrice": 85,
                "itemClassification": "Veg",
                "lastUpdatedTime": 1719174441475,
                "inStock": true
            }
        ],
        "userLocation": {
            "userLong": 81.0829548,
            "userLat": 16.7125858
        },
        "orderStatus": "New",
        "id": "1234",
        "orderTime": 1719604928996
    }
  ]
  ordersArr: any[] = [];
  newOrders: any[] = [];
  preparingOrders: any[] = [];
  readyOrders: any[] = [];
  pickedUpOrders: any[] = [];
  deliveredOrders: any[] = [];
  timerValue: number = 12;
  countdown: string = '05:00'; // Initial countdown value
  intervalId: any; // To store the interval reference
  overlayWidthPercentage: any;
  @ViewChild('map', { static: true }) mapElement: ElementRef | undefined;
  map: google.maps.Map | undefined | any;
  start = { lat: 40.730610, lng: -73.935242 }; // Example start coordinates
  end = { lat: 40.712776, lng: -74.005974 }; // Example end coordinates
  origin: google.maps.LatLngLiteral = { lat: 16.719114, lng: 81.088308 };
  destination: google.maps.LatLngLiteral = { lat: 16.712701, lng: 81.082978 };
  i = 0;
  coordinatesArr = [
    {lat: 16.718414, lng: 81.087808},
    {lat: 16.717060, lng: 81.087375},
    {lat: 16.715699, lng: 81.087024},
    {lat: 16.714832, lng: 81.086369},
    {lat: 16.714638, lng: 81.085917},
    {lat: 16.713697, lng: 81.085558},
    {lat: 16.713105, lng: 81.085284},
    {lat: 16.712848, lng: 81.084795},
    {lat: 16.713024, lng: 81.084250},
    {lat: 16.713227, lng: 81.083648},
    {lat: 16.713156, lng: 81.083046},
    {lat: 16.712700, lng: 81.082930},
  ]
  timerSubscriptions: { [orderId: string]: Subscription } = {}; // Store subscriptions per order
  riderWaitingTimers: { [orderId: string]: Subscription } = {}; // Store timers for each rider
  handoverTimers: { [orderId: string]: Subscription } = {}; // Store timers for handover countdown

  constructor(private appService: AppService, private cdr: ChangeDetectorRef) {
    // this.preparingOrders = [
    //   {
    //       "orderStatus": "Preparing",
    //       "orderTime": 1719824706944,
    //       "restaurantLocation": {
    //           "restaurantLong": 81.0883096,
    //           "restaurantLat": 16.7190833
    //       },
    //       "orderId": "7884",
    //       "items": [
    //           {
    //               "itemClassification": "Veg",
    //               "inStock": true,
    //               "itemMakingCost": null,
    //               "itemImageUrl": null,
    //               "itemSellingPrice": 89,
    //               "lastUpdatedBy": null,
    //               "itemDescription": null,
    //               "itemQuantity": 1,
    //               "lastUpdatedTime": 1719174992489,
    //               "itemName": "Mint Mocktail",
    //               "itemTotalPrice": 89,
    //               "discount":0,
    //               "total":89
    //           }
    //       ],
    //       "rider":[
    //         {
    //               "riderName": "Raju",
    //               "riderTime": "Arriving in 5 mins",
    //               "otp": 2345,

    //         }
    //       ],
    //       "resId": "123456",
    //       "email": "abhinav.chinta@adev.co.in",
    //       "id": "7884",
    //       "mobile": "+918919174919",
    //       "userLocation": {
    //           "userLat": 16.712578,
    //           "userLong": 81.082953
    //       },
    //       "name": "Abhinav Chinta"
    //   },
    //   {
    //       "orderTime": 1719751841038,
    //       "mobile": "+918919174919",
    //       "id": "9350",
    //       "name": "Abhinav Chinta",
    //       "resId": "123456",
    //       "email": "abhinav.chinta@adev.co.in",
    //       "restaurantLocation": {
    //           "restaurantLat": 16.7190833,
    //           "restaurantLong": 81.0883096
    //       },
    //       "orderStatus": "Preparing",
    //       "orderId": "9350",
    //       "items": [
    //           {
    //               "itemClassification": "Veg",
    //               "itemDescription": null,
    //               "itemImageUrl": null,
    //               "itemQuantity": 1,
    //               "lastUpdatedTime": 1719174992489,
    //               "itemMakingCost": null,
    //               "itemSellingPrice": 89,
    //               "inStock": true,
    //               "itemName": "Mint Mocktail",
    //               "lastUpdatedBy": null,                  
    //               "itemTotalPrice": 89,
    //               "discount": 0,
    //               "total": 89
    //           }
    //       ],
    //       "rider":[
    //         {
    //               "riderName": "Kiran",
    //               "riderTime": "Arrived",
    //               "otp": 4567,
    //         }
    //       ],
    //       "userLocation": {
    //           "userLat": 0,
    //           "userLong": 0
    //       }
    //   }
    // ]
    var timer = setInterval(() => {
      if(this.appService.selectedRestaurantId) {
        clearInterval(timer);
        this.appService.restaurantCollection.doc(this.appService.selectedRestaurantId).collection('activeOrders').valueChanges({idField: 'id'}).subscribe((res: any) => {
          if(res.length>0) {
            const groupedOrders = res.reduce((groups: any, order: any) => {
              const status = order.orderStatus;
              (groups[status] = groups[status] || []).push(order);
              return groups;
            }, {} as { [key: string]: any[] });
            
            this.newOrders = groupedOrders['New'] || [];
            this.preparingOrders = groupedOrders['Preparing'] || [];
            this.preparingOrders.forEach(order => {
              this.updateTimeLeft(order.orderId); // Initialize timeLeft for each order
        
              if (order.orderStatus !== 'Ready' && order.orderReadyBy) {
                this.startTimer(order.orderId);
              }

              if (order.riderDetails?.riderStatus === 2 && order.riderDetails?.riderArrivalTime) {
                this.startWaitingTimer(order.orderId);
              }
            });
            this.readyOrders = groupedOrders['Ready'] || [];
            this.readyOrders.forEach(order => {
              if (order.orderStatus === 'Ready') {
                this.startHandoverTimer(order.orderId);
              }
            })
            this.pickedUpOrders = groupedOrders['Picked Up'] || [];
            this.deliveredOrders = groupedOrders['Delivered'] || [];
            console.log('New Orders:', this.newOrders);
          console.log('Preparing Orders:', this.preparingOrders);
          console.log('Ready Orders:', this.readyOrders);
          console.log('Picked Up Orders:', this.pickedUpOrders);
          console.log('Delivered Orders:', this.deliveredOrders);

            if(this.newOrders.length > 0) {
              this.initNewOrder()
            }
          } else if (res.length == 0) {
            this.closeNewOrder()
          }
        }, err => {
          console.warn(err);
        })
      }
    }, 1000)
  }

  ngOnInit() {
    this.items = [
        { label: `New` },
        { label: `Preparing` },
        { label: `Ready`},
        { label: `Picked Up` }
    ]
    this.cdr.detectChanges()
    this.activeItem = this.items[0];
    setInterval(() => {
      this.origin = this.coordinatesArr[this.i];
      this.i += 1;
    }, 3000)
    // setTimeout(() => {
    // }, 5000);
    // this.loadMap();
  }

  loadMap() {
    const mapProperties = {
      // center: this.start,
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.mapElement?.nativeElement, mapProperties);

    this.calculateAndDisplayRoute();
  }

  ngAfterViewInit() { 
    if (this.start) {
      this.map.googleMap?.setCenter({ lat: this.start.lat, lng: this.start.lng }); // Use optional chaining
    }
  }

  calculateAndDisplayRoute() {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();

    directionsRenderer.setMap(this.map);

    directionsService.route(
      {
        origin: this.start,
        destination: this.end,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (response, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(response);
        } else {
          window.alert('Directions request failed due to ' + status);
        }
      }
    );
  }

  initNewOrder() {
    this.showNewOrder = true;
    this.startCountdown()
  }

  closeNewOrder() {
    this.showNewOrder = false;
  }

  formattedOrderTime(date: number) {
    return formatDate(date, 'hh:mm a', 'en-US'); // 'h' instead of 'hh' for no leading zero
  }

  decreaseTimer() {
    this.timerValue = Math.max(12, this.timerValue - 1); // Decrement with minimum limit
  }
  
  increaseTimer() {
    this.timerValue = Math.min(25, this.timerValue + 1); // Increment with maximum limit
  }

  approveOrder(order: any) {
    const orderReadyBy = order.orderTime + (this.timerValue * 60 * 1000); // Convert minutes to milliseconds
    order['orderReadyBy'] = orderReadyBy;
    order['orderStatus'] = 'Preparing';
    this.appService.approveOrder(order).then((res) => {
      var obj = {
        orderId: order.orderId,
        resId: this.appService.selectedRestaurantId
      }
      this.timerSubscriptions[order.orderId]?.unsubscribe();
      this.appService.newOpenOrder(obj).then((res) => {
        console.warn(res);
      })
      this.closeNewOrder()
    })
  }

  markOrderReady(order: any) {
    const orderReadyAt = order.orderTime + (this.timerValue * 60 * 1000);
    order['orderReadyAt'] = orderReadyAt;
    order['orderStatus'] = 'Ready';
    this.appService.markReady(order).then((res) => {
      console.warn('Marked Ready');
    })
  }

  rejectOrder(order: any) {
    this.appService.rejectOrder(order).then((res) => {
      console.warn(res);
    })
  }

  startCountdown() {
    let [minutes, seconds] = this.countdown.split(':').map(Number);

    this.intervalId = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        clearInterval(this.intervalId);
        this.countdown = '00:00';
        return;
      }

      if (seconds === 0) {
        minutes--;
        seconds = 59;
      } else {
        seconds--;
      }

      this.countdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }, 1000);
  }

  updateActiveItem(event: any) {
    this.activeItem = event
  }

  orderDetails() {
    this.appService.getOrder('123456', '9350').subscribe((res) => {
      console.warn(res.data());
    })
  }

  hasRiderDetails(order: any): boolean {
    return 'riderDetails' in order;
  }

  startTimer(orderId: string) {
    this.timerSubscriptions[orderId] = interval(1000).subscribe(() => {
      this.updateTimeLeft(orderId);
    });
  }

  startWaitingTimer(orderId: string) {
    this.updateTimeElapsed(orderId);
    this.riderWaitingTimers[orderId] = interval(60000).subscribe(() => { // Update every minute
      this.updateTimeElapsed(orderId);
    });
  }

  startHandoverTimer(orderId: string) {
    this.updateHandoverTimeLeft(orderId);
    this.handoverTimers[orderId] = interval(1000).subscribe(() => {
      this.updateHandoverTimeLeft(orderId);
    });
  }

  ngOnDestroy() {
    Object.values(this.timerSubscriptions).forEach(sub => sub.unsubscribe());
    Object.values(this.riderWaitingTimers).forEach(sub => sub.unsubscribe());
    Object.values(this.handoverTimers).forEach(sub => sub.unsubscribe());
  }

  updateTimeLeft(orderId: string) {
    const orderIndex = this.preparingOrders.findIndex(o => o.orderId === orderId);
    if (orderIndex !== -1) {
      const order = this.preparingOrders[orderIndex];
      const currentTime = new Date().getTime();
      const timeDifference = order.orderReadyBy - currentTime;
  
      if (timeDifference > 0) {
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        let seconds: any = Math.floor((timeDifference / 1000) % 60);
  
        // Add leading zero if seconds are less than 10
        if (seconds < 10) {
          seconds = `0${seconds}`; 
        }
  
        order.timeLeft = `${minutes}m ${seconds}s`;
      } else {
        order.timeLeft = "";
        this.timerSubscriptions[orderId]?.unsubscribe(); // Stop timer for this order
      }
  
      this.preparingOrders[orderIndex] = { ...order };
      this.cdr.detectChanges(); 
    }
  }

  calculateTotalBill(order: any): number {
    let total = 0;
    for (const item of order.items) {
      total += item.itemSellingPrice * item.itemQuantity;
    }
  
    // Apply discount if applicable
    if (order.discount && order.discount > 0) {
      total -= order.discount; // Assuming discount is a fixed amount
    }
  
    return total;
  }

  updateTimeElapsed(orderId: string) {
    const orderIndex = this.preparingOrders.findIndex(o => o.orderId === orderId);
    if (orderIndex !== -1) {
      const order = this.preparingOrders[orderIndex];
      
      if (order && order.riderDetails) {
        const currentTime = new Date().getTime();
        const elapsedMinutes = Math.floor((currentTime - order.riderDetails.riderArrivalTime) / (1000 * 60));
  
        const updatedRiderDetails = {
          ...order.riderDetails, 
          timeElapsed: `${elapsedMinutes}m` 
        };
  
        const updatedOrder = {
          ...order, 
          riderDetails: updatedRiderDetails 
        };

        this.preparingOrders[orderIndex] = updatedOrder;
        this.cdr.detectChanges();
      }
    }
  }

  updateHandoverTimeLeft(orderId: string) {
    //Find the correct order object inside the preparingOrders array
    const orderIndex = this.readyOrders.findIndex(o => o.orderId === orderId);
    if(orderIndex === -1) return;

    const order = this.readyOrders[orderIndex];

    const currentTime = new Date().getTime();
    const timeDifference = currentTime - order.orderReadyBy; 
    const totalSeconds = Math.max(0, 4 * 60 - Math.floor(timeDifference / 1000));

    if (totalSeconds > 0) {
      console.warn('if');
      
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;

      order.handOverTimeLeft = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
      console.warn('else');
      order.handOverTimeLeft = "Handover Time Exceeded"; 
      this.handoverTimers[orderId]?.unsubscribe(); 
    }

    // Update the order in the array to trigger change detection
    this.readyOrders[orderIndex] = { ...order };
    console.warn(this.readyOrders);
    
    this.cdr.detectChanges();
  }

}
