<div class="mainContainer vw-100 d-flex flex-column" [style.height.px]="screenHeight">
    <nav class="navBar whiteNav d-flex justify-content-between align-items-center w-100">
        <i class="pi pi-bars" (click)="toggleShowBar()" *ngIf="isMobile"></i>
        <span class="companyHeader">
            aDev
        </span>
        <div class="d-flex gap-2 align-items-center">
            <span class="notificationIconHolder">
                <i class="pi pi-bell"></i>
            </span>
            <span *ngIf="!isMobile">{{userName}}</span>
            <i *ngIf="!isMobile" class="pi pi-chevron-down linkBtn" (click)="op.toggle($event)"></i>
            <p-overlayPanel #op>
                <span class="opBtn" (click)="logOut()">
                    Log Out
                </span>
            </p-overlayPanel>
        </div>
    </nav>
    <div class="w-100 d-flex">
        <div class="sidePanel" *ngIf="isDesktop">
            <div class="w-100 h-100 d-flex flex-column sideBarBody">
                <div class="sideBarContent d-flex flex-column mt-4 gap-3">
                    <span class="navBtn" (click)="updateScreen('orders')">
                        Orders
                    </span>
                    <span class="navBtn" (click)="updateScreen('menu')">
                        Menu
                    </span>
                    <span class="navBtn" (click)="updateScreen('settings')">
                        Settings
                    </span>
                </div>
                <div class="userDetailSection d-flex flex-column">
                    <!-- <span>{{userName}}</span> -->
                    <span (click)="logOut()">
                        Log Out
                    </span>
                </div>
            </div>
        </div>
        <div class="w-100 d-flex p-4 py-2 flex-column gap-4 bodyContainer">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<p-sidebar [(visible)]="showSideBar" (onHide)="toggleShowBar()" [showCloseIcon]="false">
    <ng-template pTemplate="header">
        <div class="w-100 d-flex justify-content-end">
            <i class="pi pi-times" (click)="toggleShowBar()"></i>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="w-100 h-100 d-flex flex-column sideBarBody">
            <div class="sideBarContent d-flex flex-column">

            </div>
            <div class="userDetailSection d-flex flex-column">
                <span>{{userName}}</span>
                <span (click)="logOut()">
                    Log Out
                </span>
            </div>
        </div>
    </ng-template>
</p-sidebar>


<p-dialog header="Update Outlet Timings" [modal]="true" [(visible)]="showUpdateTimingsModal" [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <div>
        <div class="gap-2">
            <span class="pi pi-info-circle"></span>
            Dear <strong>{{userName}}</strong>, We see that your outlet timings are not updated. Kindly update the outlet timings to continue using aDev.
        </div>
        <div class="d-flex justify-content-end gap-2 mt-4">
            <p-button class="addBtn" [outlined]="true" severity="secondary" label="Cancel" size="small"
                (onClick)="toggleUpdateTimings()"></p-button>
            <p-button class="addBtn" severity="primary" label="Update" size="small"
                (click)="updateTimings()"></p-button>
        </div>
    </div>
</p-dialog>