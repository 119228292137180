import { ChangeDetectorRef, Component } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent {

  startTime: Date | undefined;
  endTime: Date | undefined;
  days: any[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ]

  constructor(private appService: AppService, private cdr: ChangeDetectorRef) {
    var timer = setInterval(() => {
      if(this.appService.selectedRestaurantId) {
        this.appService.getActiveRestaurantDetails(this.appService.selectedRestaurantId).subscribe((res: any) => {
          this.endTime = new Date(res.data().restaurantTimings.sunday.endTime)
          this.startTime = new Date(res.data().restaurantTimings.sunday.startTime);
          this.cdr.detectChanges(); 
        })
        clearInterval(timer);
      }
    })
  }

  saveTimings(index: any) {
    var obj = {
      monday: {
        startTime: this.startTime?.valueOf(),
        endTime: this.endTime?.valueOf()
      },
      tuesday: {
        startTime: this.startTime?.valueOf(),
        endTime: this.endTime?.valueOf()
      },
      wednesday: {
        startTime: this.startTime?.valueOf(),
        endTime: this.endTime?.valueOf()
      },
      thursday: {
        startTime: this.startTime?.valueOf(),
        endTime: this.endTime?.valueOf()
      },
      friday: {
        startTime: this.startTime?.valueOf(),
        endTime: this.endTime?.valueOf()
      },
      saturday: {
        startTime: this.startTime?.valueOf(),
        endTime: this.endTime?.valueOf()
      },
      sunday: {
        startTime: this.startTime?.valueOf(),
        endTime: this.endTime?.valueOf()
      },
    }
    this.appService.updateOutletTimings(this.appService.selectedRestaurantId, obj)
  }
}
