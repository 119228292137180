<!-- <div class="bodyBg vw-100" [style.height.px]="screenHeight">
    <div class="mainContainer">
        <div class="restaurantsHolder" *ngIf="restaurantsArr.length > 0">
            <div class="restaurantCard d-flex flex-column p-4" *ngFor="let restaurant of restaurantsArr" (click)="openRestaurant(restaurant.restaurantId)">
                <span class="restaurantName">
                    {{restaurant.restaurantName}}
                </span>
                <span>
                    {{restaurant.locality}}
                </span>
            </div>
        </div>
        <div class="emptyHolder h-100 w-100 justify-content-center align-items-center d-flex" *ngIf="restaurantsArr.length == 0">
            <span class="emptyMsg">
                No Restaurants Found
            </span>
        </div>
    </div>
</div> -->
<div class="bgContainer d-flex flex-column" [style.height.px]="screenHeight">
    <nav class="navBar whiteNav d-flex justify-content-between align-items-center">
        <i class="pi pi-bars" (click)="toggleShowBar()" *ngIf="isMobile"></i>
        <span class="companyHeader">
            aDev
        </span>
        <div class="d-flex gap-2 align-items-center">
            <span class="notificationIconHolder">
                <i class="pi pi-shopping-cart "></i>
            </span>
            <span *ngIf="!isMobile">Abhinav Chinta</span>
            <i *ngIf="!isMobile" class="pi pi-chevron-down linkBtn" (click)="op.toggle($event)"></i>
            <p-overlayPanel #op>
                <span class="opBtn">
                    Log Out
                </span>
            </p-overlayPanel>
        </div>
    </nav>
    <div class="bodyContainer">
        <div class="w-100">
            <p-iconField iconPosition="left" class="w-100 searchField">
                <p-inputIcon styleClass="pi pi-search" />
                <input type="text" pInputText [placeholder]="currentPlaceholder" class="w-100"/>
            </p-iconField>
        </div>
        <div class="restaurantsHolder">
            <div class="restaurantCard" *ngFor="let restaurant of restaurantsArr">
                <div class="restaurantImg"></div>
                <div class="w-100 d-flex justify-content-between mt-2 align-items-baseline">
                    <div class="restaurantTitle">{{restaurant.restaurantName}}</div>
                    <div class="ratingDiv">4.8 <i class="pi pi-star-fill ratingIcon"></i></div>
                </div>
                <div class="localityDiv text-muted">
                    {{restaurant.locality}}
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <div class="text-muted d-flex flex-column restaurantMetaInfo">
                        <span>{{ formattedFoodItems(restaurant.restaurantCuisines) }}</span>
                        <span>₹200-₹250 for 2 people</span>
                    </div>
                    <div class="navBtn d-flex justify-content-center align-items-center" (click)="openRestaurant(restaurant.restaurantId)">
                        <i class="pi pi-arrow-right"></i>
                    </div>
                </div>
            </div>
            <div class="emptyHolder h-100 w-100 justify-content-center align-items-center d-flex" *ngIf="restaurantsArr.length == 0">
                <span class="emptyMsg">
                    No Restaurants Found
                </span>
            </div>
        </div>
        <div class="bottomBar" *ngIf="userActiveOrders.length > 0">
            <div class="viewCartBtn d-flex justify-content-center" (click)="toggleOrderDetails()">
                View Active Order: {{userActiveOrders[0].orderId}}
            </div>
        </div>
    </div>
</div>

<p-sidebar [(visible)]="showSideBar" (onHide)="toggleShowBar()" [showCloseIcon]="false">
    <ng-template pTemplate="header">
        <div class="w-100 d-flex justify-content-end">
            <i class="pi pi-times" (click)="toggleShowBar()"></i>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="w-100 h-100 d-flex flex-column sideBarBody">
            <div class="sideBarContent d-flex flex-column">

            </div>
            <div class="userDetailSection d-flex flex-column">
                <span>Abhinav Chinta</span>
                <span>
                    Log Out
                </span>
            </div>
        </div>
    </ng-template>
</p-sidebar>

<div class="orderDetailsDiv" *ngIf="showOrderDetails">
    <div class="metaInfo">
        <div class="topBar d-flex justify-content-between align-items-center">
            <span style="cursor: pointer;" (click)="toggleOrderDetails()"><i class="pi pi-arrow-left"></i></span>
            <span class="orderDetailsId">Order # {{userActiveOrders[0]?.orderId}}</span>
            <span></span>
        </div>
        <div class="detailsHolder">
            <div class="mapContainer mb-4">
                <!-- <div #mapHolder id="map"></div> -->

                <app-google-map [origin]="{ lat: userActiveOrders[0]?.restaurantLocation.restaurantLat, lng: userActiveOrders[0]?.restaurantLocation.restaurantLong}" [destination]="{ lat: userActiveOrders[0]?.userLocation.userLat, lng: userActiveOrders[0]?.userLocation.userLong}"></app-google-map>
            </div>
            <div class="orderMetaInfoContainer py-2">
                <div class="row">
                    <div class="col d-flex flex-column">
                        <div class="text-muted titleText">
                            Order Time
                        </div>
                        <div class="dataText">
                            {{userActiveOrders[0]?.orderTime | date: 'EEEE, dd MMM yyyy, hh:mm a'}}
                        </div>
                    </div>
                    <div class="col d-flex flex-column">
                        <div class="text-muted titleText">
                            Estimated Delivery
                        </div>
                        <div class="dataText">
                            {{(userActiveOrders[0]?.orderTime + 22 * 60 * 1000) | date: 'EEEE, dd MMM yyyy, hh:mm a'}}
                        </div>
                    </div>
                </div>
                <div class="row py-3">
                    <div class="col d-flex flex-column">
                        <div class="text-muted titleText">
                            Ordered By
                        </div>
                        <div class="dataText">
                            {{userActiveOrders[0]?.name}}
                        </div>
                    </div>
                    <div class="col d-flex flex-column">
                        <div class="text-muted titleText">
                            Ordered From
                        </div>
                        <div class="dataText">
                            Fruitify
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center gap-2">
                    <span class="pi pi-shop restroIcon"></span>
                    <div class="d-flex flex-column">
                        <span class="restroName">{{activeOrderRestaurant.restaurantName}}</span>
                        <span class="text-muted">{{activeOrderRestaurant.locality}}</span>
                    </div>
                </div>
                <div class="callIcon">
                    <a href="tel:+918919174919">
                        <span class="pi pi-phone"></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>