import { Component, HostListener, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent implements OnInit {
  
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  otpValue: string = '';
  userMobile: string | undefined | null;
  isOtpSent: boolean = false;
  recaptchaVerifier: any;
  confirmationResult: firebase.auth.ConfirmationResult | null = null;
  appVerifier: firebase.auth.RecaptchaVerifier | null = null;
  windowRef: any;
  constructor(public afAuth: AngularFireAuth, private router: Router, private appService: AppService) {}

  ngOnInit() {
    this.getScreenSize();
  }

  async sendVerificationCode() {
    try {
      this.appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible'
      });
      const user = this.afAuth.currentUser;
      this.confirmationResult = await this.afAuth.signInWithPhoneNumber( '+91'+this.userMobile, this.appVerifier);
      this.isOtpSent = true;
      // this.showSuccess('Otp sent successfully');
    } catch (error) {
      console.error('Error sending verification code:', error);
    }
  }

  async verifyCode() {
    try {
      if (this.confirmationResult) {
        await this.confirmationResult.confirm(this.otpValue);
        setTimeout(() => {
          console.warn('otp verified');
          var obj = {}
          this.createUser(obj)
          this.router.navigateByUrl('/registration');
        }, 1000);
      } else {
        console.error('No confirmation result available');
      }
    } catch (error) {
      console.error('Error verifying code:', error);
    }
  }

  async loginWithGoogle() {
    try {
      const user = await this.appService.googleSignIn();
      if (user) {
        // this.showSuccess("User Logged in successfully");
        this.appService.isNewUser(user.uid).subscribe((res) => {
          if(!res.exists) {
            var obj = {
              email: user.email,
              uid: user.uid,
              createdTimeStamp: Date.now(),
              onBoardingStatus: {
                currentStep: 1
              }
            }
            this.createUser(obj)
            this.router.navigate(['/registration']);
          } else {
            this.router.navigate(['/registration']);
          }
        })
      } else {
        // this.showError("Please contact admin for more information")
      }
    } catch (error) {
      console.error(error);
    }
  }

  createUser(obj: any) {
    this.appService.onBoardUser(obj.uid, obj).then((res) => {
    })
  }
  
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any) {
          this.screenHeight = window.innerHeight;
          this.screenWidth = window.innerWidth;
          if(this.screenWidth < 768) {
            this.isDesktop = false;
            this.isMobile = true;
          } else {
            this.isDesktop = true;
            this.isMobile = false;
          }
    }
}
