import firebase from 'firebase/compat/app';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../../app.service';
import { FileUpload, UploadEvent } from 'primeng/fileupload';
import { RazorpayService } from '../../../razorpay.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';

interface TimelineStep {
  title: string;
  status: 'completed' | 'in-progress' | 'not-started';
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss'
})
export class RegistrationComponent implements OnInit{
    
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  user: any;
  registrationCurrentStep: number = 0;
  billAmount: number = 0;
  activeStep: number = 0;
  steps: TimelineStep[] = [
    { title: 'User Details', status: 'not-started' },
    { title: 'User Verification', status: 'not-started' },
    { title: 'Restaurant Details', status: 'not-started' },
    { title: 'Restaurant Verification', status: 'not-started' },
    { title: 'Review and Pay', status: 'not-started' }
  ];
  firstName: string = '';
  lastName: string = '';
  userEmail: string | null = '';
  userMobile: string | null = '';
  restaurantName: string = '';
  restaurantType: string = 'Cafe';
  ownerNumber: string = '';
  restaurantNumber: string = '';
  restaurantLong: number = 0;
  restaurantLat: number = 0;
  isPaymentDone: boolean = false;
  successModal: boolean = false;
  
  @ViewChild('fileUpload') fileUpload: FileUpload | any; // Get reference to FileUpload

  constructor(private appService: AppService, private razorpayService: RazorpayService, 
    private fireStorage: AngularFireStorage, private router: Router) {
    this.getScreenSize()
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.userEmail = user.email
        // this.appService.activeUser = this.user
        this.updateStatus()
      } else {
        // User not logged in or has just logged out.
      }
    });
  }

  ngOnInit() {
  }

  updateStatus() {
    this.appService.getRestaurantOwnerDetails(this.user.uid).subscribe((res: any) => {
      if(res.data().isRestaurantApproved) {
        console.warn(this.user);
        
        console.warn('restaurant approved');
        this.router.navigateByUrl('dashboard')
      }
      this.registrationCurrentStep = res.data().onBoardingStatus.currentStep
      this.activeStep = this.registrationCurrentStep
      this.isPaymentDone = res.data().isPaymentDone
      this.ownerNumber = res.data().mobile
      switch(this.registrationCurrentStep) {
        case 1: {
          this.steps[0].status = 'in-progress'
          this.steps[1].status = 'not-started'
          this.steps[2].status = 'not-started'
          this.steps[3].status = 'not-started'
          this.steps[4].status = 'not-started'
          break;
        }
        case 2: {
          this.steps[0].status = 'completed'
          this.steps[1].status = 'in-progress'
          this.steps[2].status = 'not-started'
          this.steps[3].status = 'not-started'
          this.steps[4].status = 'not-started'
          break;
        }
        case 3: {
          this.steps[0].status = 'completed'
          this.steps[1].status = 'completed'
          this.steps[2].status = 'in-progress'
          this.steps[3].status = 'not-started'
          this.steps[4].status = 'not-started'
          break;
        }
        case 4: {
          this.steps[0].status = 'completed'
          this.steps[1].status = 'completed'
          this.steps[2].status = 'completed'
          this.steps[3].status = 'in-progress'
          this.steps[4].status = 'not-started'
          break;
        }
        case 5: {
          this.steps[0].status = 'completed'
          this.steps[1].status = 'completed'
          this.steps[2].status = 'completed'
          this.steps[3].status = 'completed'
          this.steps[4].status = 'in-progress'
          if(this.isPaymentDone) {
            this.showSuccessModal()
            this.steps[4].status = 'completed'
          }
          break;
        }
      }
      
    })
  }

  isLastStep(step: TimelineStep) {
    return this.steps.indexOf(step) === this.steps.length - 1;
  }

  async saveCurrentStep() {
    if(this.activeStep == 1) {
      var obj = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.userEmail,
        mobile: this.userMobile
      }
      this.appService.updateOnBoardingProcess(this.activeStep, this.user.uid, obj).then((res) => {
        this.updateStatus()
      })
    } else if (this.activeStep == 2) {
      const selectedFile = this.fileUpload.files[0];

      if (selectedFile) {
        const filePath = `onBoarding/${this.user.uid}/userDocs/${selectedFile.name}`; // Build the path
        const uploadTask = this.fireStorage.upload(filePath, selectedFile)
        const url = (await (await uploadTask).ref.getDownloadURL()).valueOf()
        var idObj = {
          idURL: url
        }
        this.appService.updateOnBoardingProcess(this.activeStep, this.user.uid, idObj).then((res) => {
          this.updateStatus()
        })
        console.log("File upload started...");
      } else {
        console.warn('error');
        // this.messageService.add({severity:'warn', summary:'Warning', detail:'No file selected.'});
      }
    } else if (this.activeStep == 3) {
      var restroObj = {
        restaurantName: this.restaurantName,
        restaurantType: this.restaurantType,
        ownerNumber: this.ownerNumber,
        restaurantNumber: this.restaurantNumber,
        restaurantLong: this.restaurantLong,
        restaurantLat: this.restaurantLat,
      }
      this.appService.updateOnBoardingProcess(this.activeStep, this.user.uid, restroObj).then((res) => {
        this.updateStatus()
      })
    } else if (this.activeStep == 4) {
      const selectedFile = this.fileUpload.files[0];

      if (selectedFile) {
        const filePath = `onBoarding/${this.user.uid}/RestaurantDocs/${selectedFile.name}`; // Build the path
        const uploadTask = this.fireStorage.upload(filePath, selectedFile)
        const url = (await (await uploadTask).ref.getDownloadURL()).valueOf()
        var idObj = {
          idURL: url
        }
        this.appService.updateOnBoardingProcess(this.activeStep, this.user.uid, idObj).then((res) => {
          this.updateStatus()
        })
        console.log("File upload started...");
      } else {
        console.warn('error');
        // this.messageService.add({severity:'warn', summary:'Warning', detail:'No file selected.'});
      }
    } 
  }

  initiatePayment() {
    var obj = {
      name: this.user.displayName,
      email: this.user.email,
      mobile: this.ownerNumber
    }
    const amount = 10 * 100; // Amount in currency subunits. Here 1000 means 10.00 INR
    const key = 'rzp_live_0pr1DoN6E38yAo'; // Replace with your actual API key
    this.razorpayService.payWithRazorpay(amount, key, obj)
    .then((success: any) => {
      if (success) {
        console.log('Payment was successful');
        this.appService.updateOnBoardingProcess(this.activeStep, this.user.uid, obj).then((res) => {
          this.updateStatus()
        })
        // Further actions for successful payment
      }
    })
    .catch((error: any) => {
      if (!error) {
        console.log('Payment failed');
        // Further actions for failed payment
      }
    });  
  }

  async getCurrentLocation() {
    const permission = await navigator.permissions.query({ name: 'geolocation' });
    if (permission.state === 'granted') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.restaurantLong = position.coords.longitude
            this.restaurantLat = position.coords.latitude
          },
          (error) => {
            console.error("Error getting location:", error.message);
            setTimeout(() => {
              this.getCurrentLocation()
              console.warn('Retrying');
            }, 4000);
          },
          { enableHighAccuracy: true, timeout: 4000 }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    } else if (permission.state === 'prompt') {
      const newPermission = await navigator.geolocation.getCurrentPosition(() => {
        console.warn('success');
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.restaurantLong = position.coords.longitude
              this.restaurantLat = position.coords.latitude
            },
            (error) => {
              console.error("Error getting location:", error.message);
              setTimeout(() => {
                this.getCurrentLocation()
                console.warn('Retrying');
              }, 4000);
            },
            { enableHighAccuracy: true, timeout: 4000 }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }

      }
      );
    } else {
      console.error("Location permission denied.");
    }
  }

  showSuccessModal() {
    this.successModal = true;
  }

  updateLocation(newLocation: { lat: number, lng: number }) {
    this.restaurantLat = newLocation.lat;
    this.restaurantLong = newLocation.lng;
  }
  
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if(this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}