<div class="map-container">
    <google-map [options]="mapOptions" (mapClick)="onMarkerDragEnd($event)">
      <!-- <map-marker 
        [position]="{ lat: lat, lng: lng }" 
        [options]="markerOptions" 
        (dragend)="onMarkerDragEnd($event)">
      </map-marker> -->
      <map-marker [position]="origin" 
              [options]="originMarkerOptions">
  </map-marker>

  <map-marker [position]="destination" 
              [options]="destinationMarkerOptions">
  </map-marker>
      <map-directions-renderer *ngIf="directionsResults"
                          [directions]="directionsResults">
  </map-directions-renderer>
    </google-map>
  </div>
  